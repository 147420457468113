import { getDefaultOptions } from 'API/auth'
import { removeStudent } from 'API/course'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { getUserData } from 'API/user'
import { Link, PageProps } from 'gatsby'
import { useGetUsersData } from 'hooks/useGetUsersData'
import React from 'react'
import { useAppDispatch } from 'redux/hooks'
import { ApplicationStateActions } from 'redux/slices/ApplicationState'
import { PromiseType } from 'utility-types'

const UserDetails: React.FC<PageProps> = ({ params: { user_id } }) => {
	const { user, UserDetails, setUser } = useGetUsersData({ user_id })
	const [userData, setUserData] = React.useState<PromiseType<ReturnType<typeof getUserData>>>()
	const dispatch = useAppDispatch()

	React.useEffect(() => {
		const run = async () => {
			const userData = await getUserData()
			if (userData) {
				setUserData(userData)
			}
		}
		run()
	}, [])

	return (
		<>
			{UserDetails}
			{user && (
				<>
					{/* Form to update first name and last name*/}
					<form
						onSubmit={async (e) => {
							e.preventDefault()
							console.log(user)
							await handleApi(
								axios.post(
									getUrl('user/update_user'),
									{
										uid: user.uid,
										first_name: user.first_name,
										last_name: user.last_name,
										username: user.username,
										email: user.email,
									},
									{ ...getDefaultOptions() }
								)
							)
							console.log('updated!')
						}}
						className='form-inline'
					>
						<div className='form-group mb-2'>
							<label htmlFor='firstName' className='sr-only'>
								First Name
							</label>
							<input
								type='text'
								className='form-control'
								id='firstName'
								placeholder='First Name'
								value={user.first_name}
								onChange={(e) => {
									setUser({ ...user, first_name: e.target.value })
								}}
							/>
						</div>
						<div className='form-group mx-sm-3 mb-2'>
							<label htmlFor='lastName' className='sr-only'>
								Last Name
							</label>
							<input
								type='text'
								className='form-control'
								id='lastName'
								placeholder='Last Name'
								value={user.last_name}
								onChange={(e) => {
									setUser({ ...user, last_name: e.target.value })
								}}
							/>
						</div>
						<div className='form-group mb-2'>
							<label htmlFor='firstName' className='sr-only'>
								username
							</label>
							<input
								type='text'
								className='form-control'
								id='username'
								placeholder='Username'
								value={user.username}
								onChange={(e) => {
									setUser({ ...user, username: e.target.value })
								}}
							/>
						</div>
						<div className='form-group mb-2'>
							<label htmlFor='email' className='sr-only'>
								email
							</label>
							<input
								type='text'
								className='form-control'
								id='email'
								placeholder='Email'
								value={user.email}
								onChange={(e) => {
									setUser({ ...user, email: e.target.value })
								}}
							/>
						</div>
						{userData?.admin && (
							<button type='submit' className='btn btn-primary mb-2'>
								Update
							</button>
						)}
					</form>
					{user.admin === false && userData?.admin === true && (
						<button
							type='submit'
							className='btn btn-primary mb-2'
							onClick={async (e) => {
								e.preventDefault()
								console.log(user)
								const result = await handleApi(
									axios.post(
										getUrl('user/make_admin'),
										{
											uid: user.uid,
										},
										{ ...getDefaultOptions() }
									)
								)
								if (result) {
									alert('updated')
								}
							}}
						>
							Make Admin
						</button>
					)}
					{user.admin && userData?.admin === true && (
						<button
							type='submit'
							className='btn btn-primary mb-2'
							onClick={async (e) => {
								e.preventDefault()
								console.log(user)
								const result = await handleApi(
									axios.post(
										getUrl('user/remove_admin'),
										{
											uid: user.uid,
										},
										{ ...getDefaultOptions() }
									)
								)
								if (result) {
									alert('updated')
								}
							}}
						>
							Remove Admin
						</button>
					)}
					{userData?.admin === true && !user?.profile_blocked && (
						<button
							type='submit'
							className='btn btn-primary mb-2'
							onClick={async (e) => {
								e.preventDefault()
								console.log(user)
								const result = await handleApi(
									axios.post(
										getUrl('user/block_profile'),
										{
											uid: user.uid,
										},
										{ ...getDefaultOptions() }
									)
								)
								if (result) {
									alert('updated')
								}
							}}
						>
							Block Profile
						</button>
					)}
					{userData?.admin === true && user?.profile_blocked && (
						<button
							type='submit'
							className='btn btn-primary mb-2'
							onClick={async (e) => {
								e.preventDefault()
								console.log(user)
								const result = await handleApi(
									axios.post(
										getUrl('user/unblock_profile'),
										{
											uid: user.uid,
										},
										{ ...getDefaultOptions() }
									)
								)
								if (result) {
									alert('updated')
								}
							}}
						>
							UnBlock Profile
						</button>
					)}
					{user.manager === false && userData?.admin === true && (
						<button
							type='submit'
							className='btn btn-primary mb-2'
							onClick={async (e) => {
								e.preventDefault()
								console.log(user)
								const result = await handleApi(
									axios.post(
										getUrl('user/make_manager'),
										{
											uid: user.uid,
										},
										{ ...getDefaultOptions() }
									)
								)
								if (result) {
									alert('updated')
								}
							}}
						>
							Make Manager
						</button>
					)}
					{user.manager && userData?.admin === true && (
						<button
							type='submit'
							className='btn btn-primary mb-2'
							onClick={async (e) => {
								e.preventDefault()
								console.log(user)
								const result = await handleApi(
									axios.post(
										getUrl('user/remove_manager'),
										{
											uid: user.uid,
										},
										{ ...getDefaultOptions() }
									)
								)
								if (result) {
									alert('updated')
								}
							}}
						>
							Remove Manager
						</button>
					)}
					<h2>Courses</h2>{' '}
					<table className='table'>
						<thead>
							<tr>
								<th scope='col'>#</th>
								<th scope='col'>Course Name</th>
								<th scope='col'>Position</th>
								<th scope='col'>Description</th>
								{userData?.admin === true && <th scope='col'>Delete</th>}
								{userData?.admin === true && <th scope='col'>Logs</th>}
							</tr>
						</thead>
						<tbody>
							{user.courses.map((elem, index) => {
								const { course } = elem
								return (
									<>
										<tr>
											<td scope='row'>{index + 1}</td>
											<Link to={`/admin/course/${course.uid}`}>
												<td>{course.name}</td>
											</Link>
											<td>{elem?.type}</td>
											<td>{course.description}</td>
											<td>
												{userData?.admin === true && (
													<div
														onClick={async () => {
															if (window.confirm('Remove user?')) {
																await removeStudent(user.uid, course.uid)
																dispatch(ApplicationStateActions.setUpdate())
																// setUpdate((update) => update + 1)
															}
														}}
														className='btn btn-danger'
													>
														Remove user from Course
													</div>
												)}
											</td>
											{userData?.admin === true && (
												<td>
													<Link to={`/admin/course/${course.uid}/logs/${user.uid}`}>Logs</Link>
												</td>
											)}
										</tr>
									</>
								)
							})}
						</tbody>
					</table>
				</>
			)}{' '}
		</>
	)
}

export default UserDetails
