import { getUserDataByUid } from 'API/user'
import React from 'react'
import { useAppSelector } from 'redux/hooks'
import { CourseType } from 'types/CourseAdditionMetaData'
import { UserType } from 'types/UserAdditionMetaData'

export const useGetUsersData = ({ user_id }: { user_id: string }) => {
	const [user, setUser] = React.useState<UserType & { courses: { course: CourseType }[] }>()
	const update = useAppSelector((state) => state.ApplicationReducer.update)
	React.useEffect(() => {
		const run = async () => {
			const user = await getUserDataByUid(user_id)
			console.log(user)
			if (user) {
				setUser(user)
			}
		}
		run()
	}, [user_id, update])

	const UserDetails = user && (
		<>
			<>
				<div className='px-2'>
					<h1 className='pt-2'>{user.first_name + ' ' + user.last_name}</h1>
				</div>
			</>
		</>
	)
	return { user, setUser, UserDetails }
}
